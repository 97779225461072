import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import {
  Dialog,
  Slide,
  AppBar,
  Toolbar,
  CircularProgress,
} from "@material-ui/core";

// Transition for Dialog
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddUpdateTasks = (props) => {
  const [saveIsLoading, setSaveIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    notes: "",
    estimated_end_date: null,
    start_date: null,
  });

  // State to store the dropdown options for vendors and users
  const [vendors, setVendors] = useState([]);
  const [users, setUsers] = useState([]);

  // Reset form to initial state
  const resetForm = () => {
    setFormData({
      name: "",
      notes: "",
      estimated_end_date: null,
      start_date: null,
    });
  };

 

  // On component mount or prop change, fetch the data
  useEffect(() => {
    if (props.open) {
      if (props.id > 0) {
        getFormData();
      } else {
        resetForm();
      }
    } else {
      resetForm();
    }
  }, [props.open, props.id]);

  const getFormData = () => {
    if (!props.id) return;

    axios({
      method: "get",
      url: `${process.env.REACT_APP_API}/admin/tasks/getById/${props.id}`,
    })
      .then((response) => {
        setFormData({
          id: props.id,
          name: response.data.name,
          notes: response.data.notes,
          estimated_end_date: response.data.estimated_end_date,
          start_date: response.data.start_date,
        });
      })
      .catch((error) => {
        toast.error("Failed to load data");
      });
  };

  const handleInputChange = (e) => {
    e.preventDefault();
    const { id, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  const saveForm = () => {
    setSaveIsLoading(true);
  
    // Prepare the data to be sent, including project_id
    const requestData = {
      ...formData,
      project_id: props.projectId,  // Add project_id here
    };
  
    const apiUrl =
      props.id > 0
        ? `${process.env.REACT_APP_API}/admin/tasks/update`
        : `${process.env.REACT_APP_API}/admin/tasks/add`;
  
    axios({
      method: "post",
      url: apiUrl,
      data: requestData,  // Send the requestData which includes formData and project_id
    })
      .then((response) => {
        toast.success("Saved successfully");
        setSaveIsLoading(false);
        props.onClose(true);
      })
      .catch((error) => {
        setSaveIsLoading(false);
        toast.error("Error while saving");
      });
  };
  

  useEffect(() => {
    resetForm();
  }, [props.open]);

  return (
    <Dialog
      fullScreen
      open={props.open}
      onClose={props.onClose}
      TransitionComponent={Transition}
      style={{ zIndex: 2235 }}
    >
      <AppBar className="position-relative">
        <Toolbar>
          <button
            type="button"
            className="close-modal-button"
            onClick={() => props.onClose(false)}
          >
            &times;
          </button>
          <h4 className="text-white mx-3 my-0">Edit Tasks</h4>
        </Toolbar>
      </AppBar>

      <div style={{ padding: "25px" }}>
        <div className="row">
          <div className="col-sm-6">
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                className="form-control"
                id="name"
                onChange={handleInputChange}
                value={formData.name}
              />
            </div>
          </div>

          <div className="col-sm-12 col-md-6">
            <div className="form-group">
              <label htmlFor="start_date">Start Date</label>
              <input
                type="date"
                className="form-control"
                id="start_date"
                value={formData.start_date || ""}
                onChange={handleInputChange}
              />
            </div>
          </div>

          <div className="col-sm-12 col-md-6">
            <div className="form-group">
              <label htmlFor="estimated_end_date">Estimated End Date</label>
              <input
                type="date"
                className="form-control"
                id="estimated_end_date"
                value={formData.estimated_end_date || ""}
                onChange={handleInputChange}
              />
            </div>
          </div>

          <div className="col-sm-6">
            <div className="form-group">
              <label htmlFor="notes">Notes</label>
              <input
                type="text"
                className="form-control"
                id="notes"
                onChange={handleInputChange}
                value={formData.notes}
              />
            </div>
          </div>

          <div className="col-sm-12 mt-5">
            {saveIsLoading ? (
              <CircularProgress />
            ) : (
              <button
                className="btn btn-md btn-primary float-end"
                onClick={saveForm}
              >
                Save
              </button>
            )}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default AddUpdateTasks;

import React, { useEffect, useState } from "react";
import { createBrowserHistory } from "history";
import { HashRouter, Route, Routes as ReactRoutes, Navigate } from "react-router-dom";
import axios from "axios";
import cookie from "js-cookie";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
// import app from "./social-auth/app";
// import FrontendHomePage from "./pages/frontend/FrontendHomePage";
// import FrontendInspirationPage from "./pages/frontend/FrontendInspirationPage";
// import FrontendBlogsPage from "./pages/frontend/FrontendBlogsPage";
// import FrontendBlogPage from "./pages/frontend/FrontendBlogPage";
// import FrontendLandingPage from "./pages/frontend/FrontendLandingPage";
import AdminLogin from "./pages/backend/AdminLogin";
import AdminHomePage from "./pages/backend/AdminHomePage";
import AdminBlogs from "./pages/backend/AdminBlogs";
import AdminSocialMedia from "./pages/backend/AdminSocialMedia";
import AdminCategories from "./pages/backend/AdminCategories";
import AdminInspirations from "./pages/backend/AdminInspirations";
import AdminSubscriptionTypes from "./pages/backend/AdminSubscriptionTypes";
import AdminCountries from "./pages/backend/AdminCountries";
import AdminBidQuestions from "./pages/backend/AdminBidQuestions";
import AdminBadWords from "./pages/backend/AdminBadWords";
import AdminGovernorates from "./pages/backend/AdminGovernorates";
import AdminRegions from "./pages/backend/AdminRegions";
import AdminNewsLetter from "./pages/backend/AdminNewsLetter";
import Salesmen from "./pages/backend/Salesmen";
import allActions from "./actions/allActions";
// import FrontendCategoryPage from "./pages/frontend/FrontendCategoryPage";
// import FrontendVendorProfile from "./pages/frontend/FrontendVendorProfile";
// import FrontendAboutusPage from "./pages/frontend/FrontendAboutusPage";
import AdminAboutus from "./pages/backend/AdminAboutus";
import AdminAboutUsSectionTwo from "./pages/backend/AdminAboutUsSectionTwo";
import AdminAboutUsSectionThree from "./pages/backend/AdminAboutUsSectionThree";
import AdminAboutUsSectionFour from "./pages/backend/AdminAboutUsSectionFour";
import AdminAboutUsSectionFive from "./pages/backend/AdminAboutUsSectionFive";
// import SignInPage from "./pages/frontend/SignInPage";
// import SignUpPage from "./pages/frontend/SignUpPage";
import AdminReviews from "./pages/backend/AdminReviews";
import AdminVendors from "./pages/backend/AdminVendors";
import AdminCustomers from "./pages/backend/AdminCustomers";
// import UserProfile from "./pages/frontend/UserProfile";
// import Feeds from "./pages/frontend/Feeds";
import AdminReports from "./pages/backend/AdminReports";
// import TermsConditions from "./pages/frontend/TermsConditions";
import AdminFeedAd from "./pages/backend/AdminFeedAd";
import AdminSignUp from "./pages/backend/AdminSignUp";
import AdminTermsConditions from "./pages/backend/AdminTermsConditions";
import AdminChats from "./pages/backend/AdminChats";
import AdminAds from "./pages/backend/AdminAds";
// import FrontendContactUsPage from "./pages/frontend/FrontendContactUsPage";
import AdminContactUsMessages from "./pages/backend/AdminContactUsMessages";
import FrontendCustomerProfile from "./pages/frontend/FrontendCustomerProfile";
// import PayPalPage from "./pages/frontend/PayPalPage";
// const FrontendSearchPage = React.lazy(() => import("./pages/frontend/FrontendSearchPage"));
// import FrontendSearchPage from "./pages/frontend/FrontendSearchPage";
import AdminJobs from "./pages/backend/Jobs/AdminJobs";
// import CreatePage from "./pages/frontend/CreatePage";
import AdminStatuses from "./pages/backend/AdminStatuses";
import AdminPDF from "./pages/backend/AdminPDF";
import JobsCalendar from "./pages/backend/Jobs/JobsCalendar";
import JobsReport from "./pages/backend/Jobs/JobsReport";
import Projects from "./pages/backend/Projects/Projects";
import AdminUsers from "./pages/backend/AdminUsers";
import { CircularProgress } from "@mui/material";
import AdminVendorReviews from "./pages/backend/AdminVendorReviews/AdminVendorReviews";
// import FrontendShopsPage from "./pages/frontend/FrontendShopsPage";
// import FrontendCategoryShopsPage from "./pages/frontend/FrontendCategoryShopsPage";

const hist = createBrowserHistory();
const Routes = () => {
  const [user, setUser] = useState("");
  const token = cookie.get("token");

  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.currentUser);

  const loading = (
    <div className="pt-3 text-center">
      <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
  );

  useEffect(() => {
    const vaildateUser = async () => {
      // verify a token symmetric
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      // const user_type =
      // localStorage.getItem("user_type") == 0 ? "auth/admin-profile" : "mobile/auth/user-profile";
      // setUser(user_type);
      await axios
        .get(`${process.env.REACT_APP_API}/auth/admin-profile`)
        .then((res) => {
          dispatch(allActions.userActions.setUser(res.data));
        })
        .catch((err) => {
          axios.defaults.headers.common["Authorization"] = ``;
          // localStorage.clear();
          cookie.remove("token");
          dispatch(allActions.userActions.logOut());
        });
    };
    if (token) {
      vaildateUser();
    }
  }, [token]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let user = JSON.parse(localStorage.getItem("user"));
    let token = localStorage.getItem("token");

    if (user && token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      dispatch(allActions.userActions.setUser(user));
      cookie.set("token", token);
    }
  }, []);

  return (
    <HashRouter history={hist}>
      <React.Suspense fallback={loading} />
      <ToastContainer />
      {/* <ReactRoutes> */}
      {/* <Route path="*" element={<h1>Page Not Found</h1>} />
        <Route path="/" exact element={<FrontendHomePage />} />
        <Route path="/paypal/:amount" exact element={<PayPalPage />} />
        <Route path="/home" exact element={<FrontendHomePage />} />
        <Route path="/search" exact element={<FrontendSearchPage />} />
        <Route path="/shops" exact element={<FrontendShopsPage />} />
        <Route path="/inspirations" exact element={<FrontendInspirationPage />} />

        <Route path="/signin" exact element={<SignInPage />} />

        <Route path="/create" exact element={<CreatePage />} />
        <Route path="/signup" exact element={<SignUpPage />} />
        <Route path="/terms-and-conditions" exact element={<TermsConditions />} />

        <Route path="/privacy" exact element={<TermsConditions />} /> */}

      {/* {currentUser.loggedIn === true && currentUser.user.user_type ? (
          <Route path="/home" exact element={<Feeds />} />
        ) : (
          <Route path="/feeds" exact element={<SignInPage />} />
        )} */}
      {/* <Route path="/home" exact element={<Feeds />} /> */}
      {/* <Route path="/aboutus" exact element={<FrontendAboutusPage />} />

        <Route path="/contactus" exact element={<FrontendContactUsPage />} />

        <Route path="/vendors/:category" exact element={<FrontendCategoryPage />} />

        <Route path="/shops/:category" exact element={<FrontendCategoryShopsPage />} />

        <Route path="/vendor/:id" exact element={<FrontendVendorProfile />} />
        <Route path="/customer/:id" exact element={<FrontendCustomerProfile />} /> */}
      {/* <Route path="/blogs" exact element={<FrontendBlogsPage />} /> */}
      {/* <Route path="/blog/:id" exact element={<FrontendBlogPage />} /> */}
      {/* {currentUser.loggedIn === true && currentUser.user.user_type ? (
          <Route path="/profile" exact element={<UserProfile />} />
        ) : (
          <Route path="/profile" exact element={<SignInPage />} />
        )} */}
      {/* <Route path="/admin" exact element={<AdminLogin />} /> */}
      {currentUser.loggedIn === true && !currentUser.user.user_type ? (
        <>
          <ReactRoutes>
            <Route path="*" name="Home" exact element={<AdminHomePage />} />
            <Route path="/admin/users" exact element={<AdminUsers />} />

            <Route path="/admin/homepage" exact element={<AdminHomePage />} />
            <Route path="/admin/blogs" exact element={<AdminBlogs />} />
            <Route path="/admin/socialMedia" exact element={<AdminSocialMedia />} />
            <Route path="/admin/categories" exact element={<AdminCategories />} />

            <Route path="/admin/statuses" exact element={<AdminStatuses />} />

            <Route path="/admin/inspirations" exact element={<AdminInspirations />} />
            <Route path="/admin/vendors" exact element={<AdminVendors />} />
            <Route path="/admin/customers" exact element={<AdminCustomers />} />

            <Route path="/admin/chats" exact element={<AdminChats />} />
            <Route path="/admin/pdf" exact element={<AdminPDF />} />
            <Route path="/admin/aboutus" exact element={<AdminAboutus />} />

            <Route path="/admin/aboutus/sectiontwo" exact element={<AdminAboutUsSectionTwo />} />
            <Route path="/admin/aboutus/sectionthree" exact element={<AdminAboutUsSectionThree />} />
            <Route path="/admin/aboutus/sectionfour" exact element={<AdminAboutUsSectionFour />} />
            <Route path="/admin/aboutus/sectionfive" exact element={<AdminAboutUsSectionFive />} />

            <Route path="/admin/signup" exact element={<AdminSignUp />} />
            <Route path="/admin/terms-and-conditions" exact element={<AdminTermsConditions />} />

            <Route path="/admin/reviews" exact element={<AdminReviews />} />
            <Route path="/admin/vendorReviews" exact element={<AdminVendorReviews />} />
            <Route path="/admin/Jobs" exact element={<AdminJobs />} />

            <Route path="/admin/JobsCalendar" exact element={<JobsCalendar />} />

            <Route path="/admin/JobsReport" exact element={<JobsReport />} />
            <Route path="/admin/Projects" exact element={<Projects />} />

            <Route path="/admin/subscriptionTypes" exact element={<AdminSubscriptionTypes />} />
            <Route path="/admin/countries" exact element={<AdminCountries />} />

            <Route path="/admin/bidQuestions" exact element={<AdminBidQuestions />} />

            <Route path="/admin/badWords" exact element={<AdminBadWords />} />

            <Route path="/admin/governorates" exact element={<AdminGovernorates />} />
            <Route path="/admin/regions" exact element={<AdminRegions />} />
            <Route path="/admin/NewsLetter" exact element={<AdminNewsLetter />} />
            <Route path="/admin/salesmen" exact element={<Salesmen />} />

            <Route path="/admin/reports" exact element={<AdminReports />} />

            <Route path="/admin/feedad" exact element={<AdminFeedAd />} />

            <Route path="/admin/ads" exact element={<AdminAds />} />

            <Route path="/admin/contactus-messages" exact element={<AdminContactUsMessages />} />
          </ReactRoutes>
        </>
      ) :
        token ?
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
            <CircularProgress />
          </div>
          :
          <>
            <ReactRoutes>
              <Route path="*" element={<Navigate to="/admin" replace />} />
              <Route exact path="/admin" name="Login Page" element={<AdminLogin />} />
            </ReactRoutes>
          </>
      }

    </HashRouter>
  );
};

export default Routes;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import {
  Dialog,
  Slide,
  AppBar,
  Toolbar,
  CircularProgress,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import CustomMuiDataTable from "src/Components/CustomMuiDataTable";
import AddIcon from "@material-ui/icons/Add";
import AddUpdateSubTasks from "./AddUpdateSubTasks";
import UpdateStatusModal from "./UpdateStatusModal";
import ViewHistory from "./ViewHistory";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ViewSubTasks = (props) => {
  const [saveIsLoading, setSaveIsLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [historyVisible, setHistoryVisible] = useState(false);
  const [subTasksVisible, setSubTasksVisible] = useState(false);
  const [statusModalVisible, setStatusModalVisible] = useState(false);
  const [selectedTaskId, setSelectedTaskId] = useState(null);
  const [currentStatus, setCurrentStatus] = useState("");
  const [id, setId] = useState(-1);
  const [data, setData] = useState([]);

  const [formData, setFormData] = useState({
    name: "",
    vendor_id: null,
    user_id: null,
    start_date: null,
  });

  const resetForm = () => {
    setFormData({
      name: "",
      vendor_id: null,
      user_id: null,
      start_date: null,
    });
  };

  const addForm = () => {
    setId(-1);
    setVisible(true);
  };

  const updateFrom = (meta) => {
    const id = meta.rowData[0];
    setId(id);
    setVisible(true);
  };

  const viewHistory = (meta) => {
    const subTaskId = meta.rowData[0]; // Capture the task ID from the selected row
    setId(subTaskId); // Set the task ID to be passed to the ViewSubTasks component
    setHistoryVisible(true); // Show the ViewSubTasks component
  };

  const handleStatusClick = (meta) => {
    const id = meta.rowData[0];
    setId(id);
    setStatusModalVisible(true);
  };

  const handleClose = (refresh) => {
    setStatusModalVisible(false);
    setHistoryVisible(false);
    setVisible(false);
    if (refresh) {
      getData();
    }
  };

  useEffect(() => {
    if (props.open) {
      getData(props.taskId);
    }
  }, [props.open, props.taskId]);

  const getData = (taskId) => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API}/admin/subTasks/get`, {
        params: { task_id: taskId },
      })
      .then((response) => {
        setData(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error("Error loading tasks");
        console.error(error);
      });
  };

  const saveForm = () => {
    setSaveIsLoading(true);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API}/admin/subTasks/add`,
      data: formData,
    })
      .then((response) => {
        toast.success("Inserted successfully");
        setSaveIsLoading(false);
        props.onClose(true);
      })
      .catch((error) => {
        setSaveIsLoading(false);
        toast.error("Error while saving");
      });
  };

  const handleInputChange = (e) => {
    e.preventDefault();
    let { id, value, innerText, checked, type } = e.target;
    value = id.includes("option") ? innerText : value;
    id = id.includes("mui") ? "review_rate" : id;

    if (type === "checkbox") {
      setFormData((prev) => ({
        ...prev,
        [id]: checked ? 1 : 0,
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [id]: value,
      }));
    }
  };

  const columns = [
    {
      name: "id",
      label: "ID",
    },
    {
      name: "name",
      label: "Task Name",
    },
    {
      name: "status",
      label: "Task Status",
      options: {
        customBodyRender: (value, meta) => {
          return (
            <button onClick={() => handleStatusClick(meta)}>{value}</button>
          );
        },
      },
    },
    {
      name: "start_date",
      label: "Start Date",
    },

    {
      name: "estimated_end_date",
      label: "Estimated end Date",
    },

    {
      name: "actions",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, meta) => {
          return (
            <div>
              <button
                className="btn btn-primary mx-1"
                onClick={() => {
                  updateFrom(meta);
                }}
              >
                Update
              </button>
              <button
                className="btn btn-secondary mx-1"
                onClick={() => {
                  viewHistory(meta);
                }}
              >
                History
              </button>
            </div>
          );
        },
      },
    },
  ];

  const options = {
    filter: true,
    rowsPerPage: 50,
    rowsPerPageOptions: [20, 50, 100],
    selectableRows: "none",
    customToolbar: () => {
      return (
        <span>
          <Tooltip title={"Add"}>
            <IconButton onClick={addForm}>
              <AddIcon />
            </IconButton>
          </Tooltip>
        </span>
      );
    },
    textLabels: {
      body: {
        noMatch: isLoading ? (
          <CircularProgress />
        ) : (
          "There is no matching data to display"
        ),
      },
    },
  };

  useEffect(() => {
    resetForm();
  }, [props.open]);

  return (
    <Dialog
      fullScreen
      open={props.open}
      onClose={props.onClose}
      TransitionComponent={Transition}
      style={{ zIndex: 2235 }}
    >
      <AppBar className="position-relative">
        <Toolbar>
          <button
            type="button"
            className="close-modal-button"
            onClick={() => props.onClose(false)}
          >
            &times;
          </button>
          <h4 className="text-white mx-3 my-0">Sub Tasks</h4>
        </Toolbar>
      </AppBar>
      <div
        className="container"
        style={{ maxWidth: "1500px", margin: "0 auto" }}
      >
        <CustomMuiDataTable data={data} columns={columns} options={options} />
      </div>
      <AddUpdateSubTasks
        open={visible}
        onClose={handleClose}
        taskId={props.taskId}
        id={id}
      />
      <UpdateStatusModal
        open={statusModalVisible}
        onClose={handleClose}
        taskId={props.taskId}
        id={id}
      />
      <ViewHistory open={historyVisible} onClose={handleClose} subTaskId={id} />
    </Dialog>
  );
};

export default ViewSubTasks;

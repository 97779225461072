import moment from 'moment';


export const getDateStr = (dateString) => {
  const date = new Date(dateString.replace(/-/g, "/"));
  return date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};

export const getLandingRemainingTimeStr = () => {
  const currentDate = new Date();
  const TargetDate = new Date("June 01, 2022 00:00:00");
  const DifferenceInTime = TargetDate.getTime() - currentDate.getTime();
  var days = Math.floor(DifferenceInTime / (1000 * 60 * 60 * 24));
  var hours = Math.floor((DifferenceInTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  var minutes = Math.floor((DifferenceInTime % (1000 * 60 * 60)) / (1000 * 60));
  var seconds = Math.floor((DifferenceInTime % (1000 * 60)) / 1000);
  return {
    days: days,
    hours: hours,
    minutes: minutes,
    seconds: seconds,
  };
};

export const generateForm = (data) => {
  const formData = new FormData();

  Object.keys(data).forEach((key, index) => {
    if (key === "id" && data[key] == -1) {
      return;
    }
    if (data[key] === true || data[key] === false) {
      formData.append(key, data[key] === true ? 1 : 0);
      return;
    }

    if (data[key] === null) {
      formData.append(key, "");
      return;
    }

    formData.append(key, data[key]);
  });
  return formData;
};

export const timeSince = (dateStr) => {
  if (!dateStr) {
    return "";
  }
  let dateTimeParts = dateStr.split(/[- :]/); // regular expression split that creates array with: year, month, day, hour, minutes, seconds values
  dateTimeParts[1]--;
  const date = new Date(...dateTimeParts);
  var seconds = Math.floor((new Date() - date) / 1000);

  var interval = (seconds - 21600) / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " years ago";
  }
  interval = (seconds - 21600) / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " months ago";
  }
  interval = (seconds - 21600) / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " days ago";
  }
  interval = (seconds - 21600) / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hours ago";
  }
  interval = (seconds - 21600) / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minutes ago";
  }
  return 0 + " seconds ago";
};

export const formatAMPM = (date) => {
  try {
    if (typeof date == "string") date = new Date(date);
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  } catch (error) {
    console.log(error);
  }
};


// Helper functions to check types
const isFileList = (value) => {
  return value instanceof FileList;
};

const isMoment = (value) => {
  return moment.isMoment(value);
};

const isDate = (value) => {
  return value instanceof Date;
};

const isArray = (value) => {
  return Array.isArray(value);
};

const isObject = (value) => {
  return value !== null && typeof value === 'object' && !isFileList(value) && !isMoment(value) && !isDate(value) && !isArray(value);
};



// Convert to FormData
export const convertToFormData = (formData) => {
  const submitFormData = new FormData();

  for (const [key, value] of Object.entries(formData)) {
    if (value !== undefined && value !== null) {
      if (isFileList(value)) {
        Array.from(value).forEach(file => {
          submitFormData.append(`${key}[]`, file);
        });
      } else if (isMoment(value)) {
        submitFormData.append(key, value.format("YYYY-MM-DD"));
      } else if (isDate(value)) {
        submitFormData.append(key, moment(value).format("YYYY-MM-DD"));
      } else if (isArray(value)) {
        value.forEach((x, i) => {
          if (x instanceof File) {
            submitFormData.append(`${key}[]`, x);
          } else if (isObject(x)) {
            for (const property in x) {
              if (Object.prototype.hasOwnProperty.call(x, property)) {
                submitFormData.append(`${key}[${i}][${property}]`, x[property]);
              }
            }
          } else {
            submitFormData.append(`${key}[]`, x.toString());
          }
        });
      } else {
        submitFormData.append(key, value);
      }
    }
  }

  return submitFormData;
};


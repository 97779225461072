import {
  CardMembership,
  Category,
  Email,
  Flag,
  GraphicEq,
  Home,
  Money,
  QuestionAnswer,
  RssFeed,
  Share,
} from "@material-ui/icons";
import CollectionsIcon from "@mui/icons-material/Collections";
import ReviewsIcon from "@mui/icons-material/Reviews";
import AssignmentIcon from "@mui/icons-material/Assignment";
import InfoIcon from "@mui/icons-material/Info";
import AdUnitsIcon from "@mui/icons-material/AdUnits";
import React from "react";
import {colors} from "../helpers/colors";
import PersonIcon from "@mui/icons-material/Person";
import {CalendarMonth, PictureAsPdf, QuestionMark, Report} from "@mui/icons-material";
import ReceiptIcon from "@mui/icons-material/Receipt";
import SpellcheckIcon from "@mui/icons-material/Spellcheck";
import ChatIcon from "@mui/icons-material/Chat";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
export const AdminSidebarData = [
  {
    title: "ADMIN USERS",
    icon: <PersonIcon style={{fill: colors.primaryColor, transform: "scale(1.2)"}} size="100px" />,
    link: "/admin/users",
  },

  {
    title: "PDF",
    icon: <PictureAsPdf style={{fill: colors.primaryColor, transform: "scale(1.2)"}} size="100px" />,
    link: "/admin/pdf",
  },

  {
    title: "Home",
    icon: <Home style={{fill: colors.primaryColor, transform: "scale(1.2)"}} size="100px" />,
    link: "/admin/homepage",
  },
  {
    title: "Our journey",
    icon: <InfoIcon style={{fill: colors.primaryColor, transform: "scale(1.2)"}} size="100px" />,
    link: "/admin/aboutus",
  },

  // {
  //   title: "About us section 2",
  //   icon: (
  //     <InfoIcon
  //       style={{ fill: colors.primaryColor, transform: "scale(1.2)" }}
  //       size="100px"
  //     />
  //   ),
  //   link: "/admin/aboutus/sectiontwo",
  // },

  // {
  //   title: "About us section 3",
  //   icon: (
  //     <InfoIcon
  //       style={{ fill: colors.primaryColor, transform: "scale(1.2)" }}
  //       size="100px"
  //     />
  //   ),
  //   link: "/admin/aboutus/sectionthree",
  // },

  // {
  //   title: "About us section 4",
  //   icon: (
  //     <InfoIcon
  //       style={{ fill: colors.primaryColor, transform: "scale(1.2)" }}
  //       size="100px"
  //     />
  //   ),
  //   link: "/admin/aboutus/sectionfour",
  // },

  // {
  //   title: "About us section 5",
  //   icon: (
  //     <InfoIcon
  //       style={{ fill: colors.primaryColor, transform: "scale(1.2)" }}
  //       size="100px"
  //     />
  //   ),
  //   link: "/admin/aboutus/sectionfive",
  // },

  // {
  //   title: "Sign Up",
  //   icon: (
  //     <AppRegistrationIcon
  //       style={{ fill: colors.primaryColor, transform: "scale(1.2)" }}
  //       size="100px"
  //     />
  //   ),
  //   link: "/admin/signup",
  // },

  {
    title: "Terms and conditions",
    icon: <ReceiptIcon style={{fill: colors.primaryColor, transform: "scale(1.2)"}} size="100px" />,
    link: "/admin/terms-and-conditions",
  },

  {
    title: "Testimonials",
    icon: <ReviewsIcon style={{fill: colors.primaryColor, transform: "scale(1.2)"}} size="100px" />,
    link: "/admin/reviews",
  },
  {
    title: "Vendor Reviews",
    icon: <ReviewsIcon style={{fill: colors.primaryColor, transform: "scale(1.2)"}} size="100px" />,
    link: "/admin/vendorReviews",
  },

  {
    title: "Jobs",
    icon: <AssignmentIcon style={{fill: colors.primaryColor, transform: "scale(1.2)"}} size="100px" />,
    link: "/admin/jobs",
  },

  {
    title: "Jobs Calendar",
    icon: <CalendarMonth style={{fill: colors.primaryColor, transform: "scale(1.2)"}} size="100px" />,
    link: "/admin/JobsCalendar",
  },
  {
    title: "Jobs Report",
    icon: <GraphicEq style={{fill: colors.primaryColor, transform: "scale(1.2)"}} size="100px" />,
    link: "/admin/JobsReport",
  },
  {
    title: "Projects Report",
    icon: <GraphicEq style={{fill: colors.primaryColor, transform: "scale(1.2)"}} size="100px" />,
    link: "/admin/Projects",
  },

  {
    title: "Blogs",
    icon: <RssFeed style={{fill: colors.primaryColor, transform: "scale(1.2)"}} size="100px" />,
    link: "/admin/blogs",
  },
  {
    title: "News Letter Subscribers",
    icon: <Email style={{fill: colors.primaryColor, transform: "scale(1.2)"}} size="100px" />,
    link: "/admin/NewsLetter",
  },
  {
    title: "Social Media",
    icon: <Share style={{fill: colors.primaryColor, transform: "scale(1.2)"}} size="100px" />,
    link: "/admin/socialMedia",
  },
  // {
  //   title: "Subscription Types",
  //   icon: (
  //     <CardMembership
  //       style={{ fill: colors.primaryColor, transform: "scale(1.2)" }}
  //       size="100px"
  //     />
  //   ),
  //   link: "/admin/subscriptionTypes",
  // },
  {
    title: "Categories",
    icon: <Category style={{fill: colors.primaryColor, transform: "scale(1.2)"}} size="100px" />,
    link: "/admin/categories",
  },

  {
    title: "Statuses",
    icon: <Category style={{fill: colors.primaryColor, transform: "scale(1.2)"}} size="100px" />,
    link: "/admin/statuses",
  },

  // {
  //   title: "Inspirations",
  //   icon: <CollectionsIcon style={{fill: colors.primaryColor, transform: "scale(1.2)"}} size="100px" />,
  //   link: "/admin/inspirations",
  // },
  {
    title: "Countries",
    icon: <Flag style={{fill: colors.primaryColor, transform: "scale(1.2)"}} size="100px" />,
    link: "/admin/countries",
  },
  // {
  //   title: "Governorates",
  //   icon: (
  //     <Flag
  //       style={{ fill: colors.primaryColor, transform: "scale(1.2)" }}
  //       size="100px"
  //     />
  //   ),
  //   link: "/admin/governorates",
  // },
  {
    title: "Regions",
    icon: <Flag style={{fill: colors.primaryColor, transform: "scale(1.2)"}} size="100px" />,
    link: "/admin/regions",
  },
  // {
  //   title: "Salesmen",
  //   icon: (
  //     <Money
  //       style={{ fill: colors.primaryColor, transform: "scale(1.2)" }}
  //       size="100px"
  //     />
  //   ),
  //   link: "/admin/salesmen",
  // },

  {
    title: "Workers",
    icon: <PersonIcon style={{fill: colors.primaryColor, transform: "scale(1.2)"}} size="100px" />,
    link: "/admin/vendors",
  },

  {
    title: "Customers",
    icon: <PersonIcon style={{fill: colors.primaryColor, transform: "scale(1.2)"}} size="100px" />,
    link: "/admin/customers",
  },

  // {
  //   title: "Chats",
  //   icon: (
  //     <ChatIcon
  //       style={{ fill: colors.primaryColor, transform: "scale(1.2)" }}
  //       size="100px"
  //     />
  //   ),
  //   link: "/admin/chats",
  // },

  // {
  //   title: "Reports",
  //   icon: (
  //     <Report
  //       style={{ fill: colors.primaryColor, transform: "scale(1.2)" }}
  //       size="100px"
  //     />
  //   ),
  //   link: "/admin/reports",
  // },

  // {
  //   title: "Feed Ad",
  //   icon: (
  //     <AdUnitsIcon
  //       style={{ fill: colors.primaryColor, transform: "scale(1.2)" }}
  //       size="100px"
  //     />
  //   ),
  //   link: "/admin/feedad",
  // },

  // {
  //   title: "Search Ads",
  //   icon: (
  //     <AdUnitsIcon
  //       style={{ fill: colors.primaryColor, transform: "scale(1.2)" }}
  //       size="100px"
  //     />
  //   ),
  //   link: "/admin/ads",
  // },

  {
    title: "Contact us messages",
    icon: <Email style={{fill: colors.primaryColor, transform: "scale(1.2)"}} size="100px" />,
    link: "/admin/contactus-messages",
  },

  // {
  //   title: "Bid Questions",
  //   icon: (
  //     <QuestionMark
  //       style={{ fill: colors.primaryColor, transform: "scale(1.2)" }}
  //       size="100px"
  //     />
  //   ),
  //   link: "/admin/bidQuestions",
  // },

  // {
  //   title: "Bad Words",
  //   icon: (
  //     <SpellcheckIcon
  //       style={{ fill: colors.primaryColor, transform: "scale(1.2)" }}
  //       size="100px"
  //     />
  //   ),
  //   link: "/admin/badWords",
  // },
];

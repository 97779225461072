import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import {
  Dialog,
  Slide,
  AppBar,
  Toolbar,
  CircularProgress,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import CustomMuiDataTable from "src/Components/CustomMuiDataTable";
import AddIcon from "@material-ui/icons/Add";
import AddUpdateTasks from "./AddUpdateTasks";
import ViewSubTasks from "./ViewSubTasks";
// import AddUpdateHistory from "./AddUpdateHistory";
import UpdateVisitImages from "./UpdateVisitImages";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ViewHistory = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [subTasksVisible, setSubTasksVisible] = useState(false);
  const [statusModalVisible, setStatusModalVisible] = useState(false);
  const [id, setId] = useState(-1);
  const [data, setData] = useState([]);

  const addForm = () => {
    setId(-1);
    setVisible(true);
  };

  const updateFrom = (meta) => {
    const id = meta.rowData[0];
    setId(id);
    setVisible(true);
  };

  const handleStatusClick = (meta) => {
    const id = meta.rowData[0];
    setId(id);
    setStatusModalVisible(true);
  };

  const handleClose = (refresh) => {
    setStatusModalVisible(false);
    setVisible(false);
    setSubTasksVisible(false);

    if (refresh) {
      // console.log("Refreshing data with sub_task_id:", props.subTaskId);
      getData(props.subTaskId);  
    }
  };

  const getData = (subTaskId) => {
    setIsLoading(true);
    const url = `${process.env.REACT_APP_API}/admin/history/get`;
    const params = { sub_task_id: subTaskId };
    console.log("Params:", params);

    // console.log("Sending request to:", url, "with params:", params);
    axios
      .get(url, { params: params })
      .then((response) => {
        setData(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error("Error loading tasks");
        console.error(error);
      });
  };

  useEffect(() => {
    if (props.open) {
      console.log("Sub Task ID:", props.subTaskId);
      getData(props.subTaskId);
    }
  }, [props.open, props.subTaskId]);

  const columns = [
    {
      name: "id",
      label: "ID",
    },
    {
      name: "status",
      label: "Status",
    },
    {
      name: "notes",
      label: "Notes",
    },
    {
      name: "created_at",
      label: "Date",
    },
    {
      name: "actions",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, meta) => {
          return (
            <div>
              <button
                className="btn btn-primary mx-1"
                onClick={() => {
                  handleStatusClick(meta);
                }}
              >
                images
              </button>
            </div>
          );
        },
      },
    },
  ];

  const options = {
    filter: true,
    rowsPerPage: 50,
    rowsPerPageOptions: [20, 50, 100],
    selectableRows: "none",
    // customToolbar: () => {
    //   return (
    //     <span>
    //       <Tooltip title={"Add"}>
    //         <IconButton onClick={addForm}>
    //           <AddIcon />
    //         </IconButton>
    //       </Tooltip>
    //     </span>
    //   );
    // },
    textLabels: {
      body: {
        noMatch: isLoading ? (
          <CircularProgress />
        ) : (
          "There is no matching data to display"
        ),
      },
    },
  };

  useEffect(() => {}, [props.open]);

  return (
    <Dialog
      fullScreen
      open={props.open}
      onClose={props.onClose}
      TransitionComponent={Transition}
      style={{ zIndex: 2235 }}
    >
      <AppBar className="position-relative">
        <Toolbar>
          <button
            type="button"
            className="close-modal-button"
            onClick={() => props.onClose(false)}
          >
            &times;
          </button>
          <h4 className="text-white mx-3 my-0">History</h4>
        </Toolbar>
      </AppBar>

      <div
        className="container"
        style={{ maxWidth: "1500px", margin: "0 auto" }}
      >
        <CustomMuiDataTable data={data} columns={columns} options={options} />
      </div>
      <UpdateVisitImages
        open={statusModalVisible}
        onClose={handleClose}
        subTaskId={props.subTaskId}
        id={id}
      />
      {/* <AddUpdateHistory
        open={visible}
        onClose={handleClose}
        subTaskId={props.subTaskId}
        id={id}
      /> */}
    </Dialog>
  );
};

export default ViewHistory;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import {
  Dialog,
  Slide,
  AppBar,
  Toolbar,
  CircularProgress,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import CustomMuiDataTable from "src/Components/CustomMuiDataTable";
import AddIcon from "@material-ui/icons/Add";
import AddUpdateTasks from "./AddUpdateTasks";
import ViewSubTasks from "./ViewSubTasks";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ViewTasks = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [subTasksVisible, setSubTasksVisible] = useState(false);
  const [id, setId] = useState(-1);  
  const [data, setData] = useState([]);

 


  const addForm = () => {
    setId(-1);
    setVisible(true);
  };

  const updateFrom = (meta) => {
    const id = meta.rowData[0];
    setId(id);
    setVisible(true);
  };

  const handleClose = (refresh) => {
    setVisible(false);
    setSubTasksVisible(false);  
    if (refresh) {
      getData();
    }
  };

  const manageSubTasks = (meta) => {
    const taskId = meta.rowData[0];  // Capture the task ID from the selected row
    setId(taskId);  // Set the task ID to be passed to the ViewSubTasks component
    setSubTasksVisible(true);  // Show the ViewSubTasks component
  };

  useEffect(() => {
    if (props.open) {
      getData(props.projectId);  // Fetch tasks when modal opens
    }
  }, [props.open, props.projectId]);  // Re-run if projectId changes
  
  const getData = (projectId) => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API}/admin/tasks/get`, {
        params: { project_id: projectId },  // Pass project ID as a query parameter
      })
      .then((response) => {
        setData(response.data);  // Set the tasks data
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error("Error loading tasks");
        console.error(error);
      });
  };
  

  



  const columns = [
    {
      name: "id",
      label: "ID",
    },
    {
      name: "name",
      label: "Task Name",
    },
    {
      name: "start_date",
      label: "Start Date",
    },

    {
      name: "estimated_end_date",
      label: "Estimated end Date",
    },

    {
      name: "actions",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, meta) => {
          return (
            <div>
              <button
                className="btn btn-secondary mx-1"
                onClick={() => {
                  manageSubTasks(meta);  // Trigger manageSubTasks with selected task ID
                }}
              >
                Sub Tasks
              </button>

              <button
                className="btn btn-primary mx-1"
                onClick={() => {
                  updateFrom(meta);
                }}
              >
                Update
              </button>
            </div>
          );
        },
      },
    },
  ];

  const options = {
    filter: true,
    rowsPerPage: 50,
    rowsPerPageOptions: [20, 50, 100],
    selectableRows: "none",
    customToolbar: () => {
      return (
        <span>
          <Tooltip title={"Add"}>
            <IconButton onClick={addForm}>
              <AddIcon />
            </IconButton>
          </Tooltip>
        </span>
      );
    },
    textLabels: {
      body: {
        noMatch: isLoading ? (
          <CircularProgress />
        ) : (
          "There is no matching data to display"
        ),
      },
    },
  };

  useEffect(() => {
  }, [props.open]);

  return (
    <Dialog
      fullScreen
      open={props.open}
      onClose={props.onClose}
      TransitionComponent={Transition}
      style={{ zIndex: 2235 }}
    >
      <AppBar className="position-relative">
        <Toolbar>
          <button
            type="button"
            className="close-modal-button"
            onClick={() => props.onClose(false)}
          >
            &times;
          </button>
          <h4 className="text-white mx-3 my-0">Tasks</h4>
        </Toolbar>
      </AppBar>

      <div
        className="container"
        style={{ maxWidth: "1500px", margin: "0 auto" }}
      >
        <CustomMuiDataTable data={data} columns={columns} options={options} />
      </div>
      <ViewSubTasks open={subTasksVisible} onClose={handleClose} taskId={id} />  
      <AddUpdateTasks open={visible} onClose={handleClose} projectId={props.projectId} id={id} />
    </Dialog>
  );
};

export default ViewTasks;



import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import {
  Dialog,
  Slide,
  AppBar,
  Toolbar,
  CircularProgress,
} from "@material-ui/core";
import { FileUploader } from "react-drag-drop-files";
import imageCompression from "browser-image-compression";
import Swal from "sweetalert2";

// Transition for Dialog
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const fileTypes = ["JPG", "PNG", "JPEG"];
const UpdateVisitImages = (props) => {
  const [saveIsLoading, setSaveIsLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [viewedFiles, setViewedFiles] = useState([]);
  const handleChangeFile = async (originalFile) => {
    const file = await imageCompression(originalFile);
    const fileData = file;
    if (fileData.type.match("image.*")) {
      const randomId = -(Math.floor(Math.random() * (10000 - 1000 + 1)) + 1000);
      let temp2 = [];
      files.forEach((element) => {
        temp2.push(element);
      });
      temp2.push({ id: randomId, file: fileData });
      setFiles(temp2);

      const reader = new FileReader();
      reader.readAsDataURL(fileData);

      reader.onloadend = function (e) {
        const imagePath = reader.result;
        let temp = [];
        viewedFiles.forEach((element) => {
          temp.push(element);
        });
        temp.push({ id: randomId, path: fileData, path: imagePath });
        setViewedFiles(temp);
      };
    } else {
      toast("Only Images Can be Uploaded", {
        position: "top-right",
        type: "error",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const removeImage = (id) => {
    // delete from server
    if (id > 0) {
      Swal.fire({
        title: "Please Confirm Your Action ?",
        showCancelButton: true,
        confirmButtonText: "Delete",
        icon: "warning",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          axios({
            method: "post",
            url: `${process.env.REACT_APP_API}/admin/history/deleteImage`,
            data: { id: id },
          })
            .then((response) => {
              let arr = files;
              let arr2 = viewedFiles;
              arr = arr.filter((v) => {
                return v.id != id;
              });
              arr2 = arr2.filter((v) => {
                return v.id != id;
              });
              setFiles(arr);
              setViewedFiles(arr2);
              toast.success(`Success`);
            })
            .catch((error) => {
              toast.error(`Error`);
            });
        }
      });
    } else {
      let arr = files;
      let arr2 = viewedFiles;
      arr = arr.filter((v) => {
        return v.id != id;
      });
      arr2 = arr2.filter((v) => {
        return v.id != id;
      });
      setFiles(arr);
      setViewedFiles(arr2);
    }
  };

  const [formData, setFormData] = useState({
    // notes: "",
    // status: "",
  });

  // Reset form to initial state
  const resetForm = () => {
    setFormData({
      // notes: "",
      // status: "",
    });
    setViewedFiles([]);
    setFiles([]);
  };

  useEffect(() => {
    if (props.open) {
      if (props.id > 0) {
        getFormData();
      } else {
        resetForm();
      }
    } else {
      resetForm();
    }
  }, [props.open, props.id]);

  const getFormData = () => {
    if (!props.id) return;

    axios({
      method: "get",
      url: `${process.env.REACT_APP_API}/admin/history/getById/${props.id}`,
    })
      .then((response) => {
        setFormData({
          id: props.id,
          status: response.data.status,
        });

        let temp = [];
        response.data.images.forEach((element) => {
        console.log(`${process.env.REACT_APP_FILES_PATH}/subTasks/${element.image_name}`);

          temp.push({
            id: element.id,
            path: `${process.env.REACT_APP_FILES_PATH}/subTasks/${element.image_name}`,
          });
        });
        setViewedFiles(temp);
      })
      .catch((error) => {
        toast.error("Failed to load data");
      });
  };

  const handleInputChange = (e) => {
    e.preventDefault();
    const { id, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  const handleFileChange = (e) => {
    const files = e.target.files;
    if (files.length > 0) {
      // Add files to formData as actual file objects, not file names
      setFormData((prev) => ({
        ...prev,
        images: [...prev.images, ...Array.from(files)], // Store actual file objects
      }));
    }
  };

  const handleClearImages = () => {
    setFormData({
      ...formData,
      images: [], // Clear images
    });
  };

  const saveForm = () => {
    setSaveIsLoading(true);
  
    const formDataToSend = new FormData();
  
    // Ensure we send the correct id for the sub_task
    formDataToSend.append("id", props.id);  
    // formDataToSend.append("sub_task_id", props.id);  
  
    // If you have other data (status, notes, etc.), make sure they are appended here as well
    formDataToSend.append("status", formData.status);  
    formDataToSend.append("notes", formData.notes);  
  
    // Append the files, if any
    files?.map((fileData) => {
      formDataToSend.append("files[]", fileData.file);
    });
  
    const apiUrl = `${process.env.REACT_APP_API}/admin/history/updateImage`;
  
    axios({
      method: "post",
      url: apiUrl,
      data: formDataToSend,
    })
      .then((response) => {
        toast.success("Saved successfully");
        setSaveIsLoading(false);
        props.onClose(true); // Close the modal and refresh if necessary
      })
      .catch((error) => {
        setSaveIsLoading(false);
        toast.error("Error while saving");
      });
  };
  


  useEffect(() => {
    resetForm();
  }, [props.open]);

  return (
    <Dialog
      fullScreen
      open={props.open}
      onClose={props.onClose}
      TransitionComponent={Transition}
      style={{ zIndex: 2235 }}
    >
      <AppBar className="position-relative">
        <Toolbar>
          <button
            type="button"
            className="close-modal-button"
            onClick={() => props.onClose(false)}
          >
            &times;
          </button>
          <h4 className="text-white mx-3 my-0">Update Images</h4>
        </Toolbar>
      </AppBar>

      <div style={{ padding: "25px" }}>
        <div className="row">
          

          {/* <div className="col-sm-6">
            <div className="form-group">
              <label htmlFor="status">Status</label>
              <select
                id="status"
                className="form-control"
                onChange={handleInputChange}
                value={formData.status}
              >
                <option value="open">Open</option>
                <option value="inprogress">In Progress</option>
                <option value="close">Close</option>
                <option value="cancel">Cancel</option>
              </select>
            </div>
          </div>

          <div className="col-sm-6">
            <div className="form-group">
              <label htmlFor="notes">Notes</label>
              <input
                type="text"
                className="form-control"
                id="notes"
                onChange={handleInputChange}
                value={formData.notes}
              />
            </div>
          </div> */}

          <div className={"col-sm-12 mt-3"}>
            <FileUploader
              classes="w-100"
              handleChange={handleChangeFile}
              name="file"
              types={fileTypes}
            >
              <div
                className="w-100"
                style={{
                  minHeight: "25vh",
                  border: "2px dashed #ced4da",
                  padding: "10px",
                  textAlign: "center",
                  marginTop: "1rem",
                }}
              >
                <p>DROP YOUR IMAGES HERE</p>
              </div>
            </FileUploader>
            <div className="row mt-3">
              {viewedFiles?.map((imageData, index) => {
                return (
                  <div className="col-sm-2" key={index}>
                    <img src={imageData.path} className="w-100 mt-3" />
                    <button
                      className="btn btn-danger"
                      style={{ position: "absolute" }}
                      onClick={() => {
                        removeImage(imageData.id);
                      }}
                    >
                      X
                    </button>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="col-sm-12 mt-5">
            {saveIsLoading ? (
              <CircularProgress />
            ) : (
              <button
                className="btn btn-md btn-primary float-end"
                onClick={saveForm}
              >
                Save
              </button>
            )}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default UpdateVisitImages;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import AddIcon from "@material-ui/icons/Add";
import { CircularProgress } from "@material-ui/core";
import Swal from "sweetalert2";
import AdminLayout from "../AdminLayout";
import CustomMuiDataTable from "src/Components/CustomMuiDataTable";
import AddUpdateProjects from "./AddUpdateProjects";
import ViewTasks from "./ViewTasks";

const Projects = () => {
  const [data, setData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [tasksVisible, setTasksVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [id, setId] = useState(-1);

  const getData = () => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API}/admin/projects/get`, {})
      .then(function (response) {
        setData(response.data);
        setIsLoading(false);
      })
      .catch(function (error) {
        setIsLoading(false);
        toast("Error", {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        console.log(error);
      });
  };

  const addForm = () => {
    setId(-1);
    setVisible(true);
  };

  const updateFrom = (meta) => {
    const id = meta.rowData[0];
    setId(id);
    setVisible(true);
  };

  const manageTasks = (meta) => {
    const projectId = meta.rowData[0];  // Assuming the first column is the project ID
    setId(projectId);
    setTasksVisible(true);
  };

  const handleClose = (refresh) => {
    setVisible(false);
    setTasksVisible(false);   
    if (refresh) {
      getData();
    }
  };

  const deleteForm = (meta) => {
    const id = meta.rowData[0];
    Swal.fire({
      title: "Please Confirm Your Action ?",
      showCancelButton: true,
      confirmButtonText: "Delete",
      icon: "warning",
    }).then((result) => {
      if (result.isConfirmed) {
        axios({
          method: "post",
          url: `${process.env.REACT_APP_API}/admin/projects/delete`,
          data: { id: id },
        })
          .then((response) => {
            toast.success(`Success`);
            getData();
          })
          .catch((error) => {
            toast.error(`Error`);
          });
      }
    });
  };

  const columns = [
    {
      name: "id",
      label: "ID",
    },
    {
      name: "name",
      label: "Project Name",
    },
    {
      name: "vendor_name",
      label: "Vendor",
    },
    {
      name: "user_name",
      label: "User",
    },
    {
      name: "foreman_name",
      label: "Foreman",
    },
    {
      name: "start_date",
      label: "Start Date",
    },
    {
      name: "work_done",
      label: "Work Done",
      options: {
        customBodyRender: (value) => {
          return `${value}%`;
        },
      },
    },
    {
      name: "actions",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, meta) => {
          return (
            <div>
              <button
                className="btn btn-warning mx-1"
                onClick={() => {
                  manageTasks(meta);
                }}
              >
                Tasks
              </button>
              <button
                className="btn btn-primary mx-1"
                onClick={() => {
                  updateFrom(meta);
                }}
              >
                Update
              </button>
            </div>
          );
        },
      },
    },
  ];

  const options = {
    filter: true,
    rowsPerPage: 50,
    rowsPerPageOptions: [20, 50, 100],
    selectableRows: "none",
    customToolbar: () => {
      return (
        <span>
          <Tooltip title={"Add"}>
            <IconButton onClick={addForm}>
              <AddIcon />
            </IconButton>
          </Tooltip>
        </span>
      );
    },
    textLabels: {
      body: {
        noMatch: isLoading ? (
          <CircularProgress />
        ) : (
          "There is no matching data to display"
        ),
      },
    },
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <AdminLayout>
      <div className="container">
        <CustomMuiDataTable
          title={"Projects"}
          data={data}
          columns={columns}
          options={options}
        />
      </div>
      <AddUpdateProjects open={visible} onClose={handleClose} id={id} />
      <ViewTasks open={tasksVisible} onClose={handleClose} projectId={id} />
      </AdminLayout>
  );
};

export default Projects;
